import { Image } from 'blitz'
import React, { FC, useState } from 'react'
import { Button, Modal } from 'silicon-design-system'
import { ModalProps, ShareModal } from './ShareModal'
import ShareFill from 'public/icons/share-fill.svg'

export const RegisteredModal: FC<ModalProps> = ({ showModal, setShowModal }) => {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Image
          src={'/assets/letter.png'}
          height={180}
          width={240}
          alt="letter"
          objectFit="contain"
        />

        <div className="text-center tny:w-[240px] vsm:w-[300px] md:w-[360px]">
          <h1 className="py-4 text-3xl font-bold text-white">You&apos;re Registered!</h1>
          <p className="w-7/8 mb-4 text-base text-white2">
            Kamu sudah melakukan pendaftaran sebelumnya. Cek email-mu untuk bukti pendaftaran dan
            informasi lebih lanjut!
          </p>
          <a
            href="https://compfest.link/WAGTalkshow"
            className="text-blue-400 underline"
            target="_blank"
            rel="noreferrer"
          >
            Join WA Group Talkshow
          </a>
          <p className="pt-6 pb-2 text-sm text-white2">Ajak temanmu ikut talkshow!</p>
        </div>
        <Button
          size="full"
          variant="ghost"
          onClick={() => {
            setShowModal(false)
            setShow(true)
          }}
          text="Share"
          leftIcon={<ShareFill />}
          className="bg-transparent px-4"
        />
      </Modal>
      <ShareModal showModal={show} setShowModal={setShow} />
    </div>
  )
}
