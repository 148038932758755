import React, { FC } from 'react'
import { Button, Modal } from 'silicon-design-system'
import Whatsapp from 'public/icons/whatsapp.svg'
import Instagram from 'public/icons/instagram.svg'
import Line from 'public/icons/line.svg'
import Linkedin from 'public/icons/linkedin.svg'
import Twitter from 'public/icons/twitter.svg'
import ContentCopy from 'public/icons/content-copy.svg'
import { toast } from 'react-hot-toast'
import { useWindowSize } from 'app/core/hooks'
export interface ModalProps {
  showModal: boolean
  setShowModal: any
  caption?: string
  link?: string
}

export const ShareModal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  caption = "Millions of insight and so much fun await YOU, \ndon't hesitate to grab your chance.\nJoin the fun NOW at https://compfest.id/",
  link = 'https://www.compfest.id',
}) => {
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText('https://www.compfest.id' + window.location.pathname)
      toast.success('Link successfully copied', {
        position: 'bottom-center',
      })
    } catch (err) {
      toast.error("Your browser doesn't support copying to clipboard")
    }
  }

  const size = useWindowSize()
  const isMobile = (size.width ?? 0) < 768
  return (
    <Modal show={showModal} onClose={() => setShowModal(false)} className="mx-5 w-auto md:mx-0">
      <div className="flex flex-col justify-center space-y-5">
        <h1 className="text-center font-rflex text-3xl font-semibold">Share your experience!</h1>
        <ul className="flex flex-row justify-center space-x-5">
          <button
            onClick={() => {
              window.open('https://wa.me/?text=' + encodeURI(caption!))
            }}
          >
            <Whatsapp />
          </button>
          {/* <button>
            <Instagram />
          </button> */}
          <button
            onClick={() =>
              window.open('https://twitter.com/intent/tweet?text=' + encodeURI(caption!))
            }
          >
            <Twitter />
          </button>
          <button
            onClick={() => {
              window.open(
                isMobile
                  ? 'https://line.me/R/share?text=' + encodeURI(caption!)
                  : ' https://social-plugins.line.me/lineit/share?text=' + encodeURI(caption!),
              )
            }}
          >
            <Line />
          </button>
          <button
            onClick={() =>
              window.open(
                `https://www.linkedin.com/shareArticle?mini=true&url=https://www.compfest.id/talkshow/&summary=compfest.id&source=COMPFEST`,
              )
            }
          >
            <Linkedin />
          </button>
        </ul>
        <Button
          variant="ghost"
          text="Copy Link"
          leftIcon={<ContentCopy />}
          className="mx-auto"
          onClick={() => copyToClipboard()}
        />
      </div>
    </Modal>
  )
}
