import { BlitzLayout, useRouter } from 'blitz'
import Navbar, { NavbarProps } from 'app/core/components/Navbar'
import { LAYOUT_STYLES } from 'app/core/constants/styles'
import { ReactNode } from 'react'
import { classNames } from 'utils/functions'
import Footer from '../components/Footer'

type LayoutProps = NavbarProps & {
  hero?: ReactNode
  useFullWidthComponents?: boolean
  padding?: boolean
}

export const Layout: BlitzLayout<LayoutProps> = ({
  children,
  hero,
  useFullWidthComponents = false,
  padding = true,
  ...props
}) => {
  const router = useRouter()
  return (
    <>
      <Navbar {...props} />
      {hero}
      <div
        className={classNames(
          padding && LAYOUT_STYLES,
          useFullWidthComponents && 'overflow-x-hidden ',
        )}
      >
        <main
          className={`${padding && 'mx-auto'} min-h-[calc(100vh-4rem)] max-w-[1280px] ${
            hero ? '' : 'pt-16'
          }`}
        >
          {children}
        </main>
      </div>
      {router.pathname === '/supermarket/cart' ? '' : <Footer />}
    </>
  )
}
